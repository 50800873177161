import axios from "axios";
import { API_URL } from "@/const";

export default {
  methods: {
    // URL生成
    getSearchApiUrl() {
      const params = new URLSearchParams();
      params.append("page", this.paginationParams.page);
      params.append("pageSize", this.paginationParams.pageSize);
      params.append("sort", `${this.sortParams.column}:${this.sortParams.type}`);
      if (this.searchParam.keySearch) {
        this.searchParam.keySearch.replace(/\s/, " ").split(" ")
          .forEach((keySearch) => { params.append("keySearch", keySearch); });
      }
      if (this.searchParam.status.length) {
        this.searchParam.status.forEach((s) => {
          params.append("generateContractSearchStatusIdList", s)
        });
      } else {
        params.append("generateContractSearchStatusIdList", "")
      }
      if (this.searchParam.accountStartDateFrom) params.append("accountStartDateFrom", this.searchParam.accountStartDateFrom);
      if (this.searchParam.accountStartDateTo) params.append("accountStartDateTo", this.searchParam.accountStartDateTo);
      if (this.searchParam.cbaContractEndDateFrom) params.append("cbaContractEndDateFrom", this.searchParam.cbaContractEndDateFrom);
      if (this.searchParam.cbaContractEndDateTo) params.append("cbaContractEndDateTo", this.searchParam.cbaContractEndDateTo);
      if (this.searchParam.isAutoUpdate) {
        // [] or [true] or [false] or [true, false] の配列になる可能性がある
        // [] と [true, false] は検索条件に指定しないことと同義なので、[true] or [false] の場合のみ検索条件に追加
        if (this.searchParam.isAutoUpdate.length === 1) {
          params.append("isAutoUpdate", this.searchParam.isAutoUpdate[0]);
        }
      }

      return API_URL.GENERATOR.CONTRACT_SEARCH + '?' + params.toString();
    },

    // 排出契約を取得
    getContractSearchApi(isPaging) {
      if (!isPaging) {
        this.paginationParams.page = 1; // 1ページ目に初期化
      }
      return axios
        .get(this.getSearchApiUrl())
        .then((res) => {
          this.contractList = res.data.generatorContractList;
          this.paginationParams = res.data.searchParams.pagination;
          this.sortParams = res.data.searchParams.sorts[0];
          this.$store.commit("searchParamGeneratorContract/set", this.searchParam);
        })
        .catch((err) => {
          this.contractList = [];
          console.log(err);
        });
    },

    // 排出契約登録状況別件数取得API
    getResistCountApi() {
      return axios
        .get(API_URL.GENERATOR.CONTRACT_REGIST_COUNT)
        .then((res) => {
          this.contractRegistCount = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};