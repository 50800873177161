<template>
  <div class="l-wrapper loggedin">
    <AppHeader pageTitle="排出事業者契約一覧" />
    <AppSideMenu />
    <main class="l-main">
      <!-- 検索フォーム ============================================================ -->
      <div>
        <div class="c-searchMenu__wrap">
          <div v-if="isDisplaySearch" class="c-searchMenu">
            <div class="l-flex">
              <div v-if="shorten().length === 0">
                <div class="title icon">条件なし</div>
              </div>
              <template v-else v-for="(s, index) in shorten()">
                <div class="title" :class="[{ icon: index === 0 }]" :key="'shorten-' + index">
                  {{ s.key }}<div class="data"> : {{ s.value }}</div>
                </div>
              </template>
            </div>
            <div class="l-flex">
              <div class="c-btn secondary sm" @click="scrollTop">検索条件を変更</div>
            </div>
          </div>
        </div>
        <div class="l-full-white l-area">
          <div class="l-box l-flex">
            <h3 class="c-lead-s-black icon">検索</h3>
            <div class="c-tabs l-area-l">
              <div class="c-tabs__each" :class="{ active: !isSearchDetail }"
                  @click="isSearchDetail = false"
              >
                簡易
              </div>
              <div class="c-tabs__each" :class="{ active: isSearchDetail }"
                  @click="isSearchDetail = true"
              >
                詳細
              </div>
            </div>
          </div>
          <div>
            <div class="c-inputWrap">
              <div class="c-inputWrap__items__cols search-wrap">
                <InputText
                    class="xlg"
                    :value.sync="searchParam.keySearch"
                    inputType="text"
                    placeholder="企業名、電話番号を入力（スペース区切りでOR検索）"
                    :validation="validateMaxLen"
                    :validateArg="100"
                >
                  <span class="text-label">キーワード</span>
                </InputText>
                <div>
                  <span class="text-label">契約状況</span>
                  <div class="l-flex cols">
                    <div
                        v-for="item in statusOpt"
                        :key="'search-status-' + item.id"
                        class="c-input"
                    >
                      <div class="c-checkbox">
                        <input
                            :id="'search-status-' + item.id"
                            type="checkbox"
                            v-model="searchParam.status"
                            :value="item.id"
                        />
                        <label class="c-checkbox__label" :for="'search-status-' + item.id">
                          <span class="c-checkbox__box"></span>
                            {{ item.name }}
                            <span v-if="showcount(item.id) !== null">
                              {{ `(${showcount(item.id)})` }}
                            </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 詳細のときだけ表示 -->
            <template v-if="isSearchDetail">
              <div class="c-inputWrap">
                <div class="c-inputWrap__items__cols search-wrap">
                  <div>
                    <span class="text-label">アカウント開放日</span>
                    <div class="c-inputWrap__items__cols search-wrap">
                      <InputDate
                          :value.sync="searchParam.accountStartDateFrom"
                          placeholder="0000/00/00"
                      />
                      <span>〜</span>
                      <InputDate
                          :value.sync="searchParam.accountStartDateTo"
                          placeholder="0000/00/00"
                      />
                    </div>
                  </div>
                  <div>
                    <span class="text-label">契約終了日</span>
                    <div class="c-inputWrap__items__cols search-wrap">
                      <InputDate
                          :value.sync="searchParam.cbaContractEndDateFrom"
                          placeholder="0000/00/00"
                      />
                      <span>〜</span>
                      <InputDate
                          :value.sync="searchParam.cbaContractEndDateTo"
                          placeholder="0000/00/00"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="c-inputWrap">
                <div class="c-inputWrap__items__cols search-wrap">
                  <div>
                    <span class="text-label">契約形態</span>
                    <div class="l-flex cols">
                      <div class="c-input">
                        <div class="c-checkbox">
                          <input
                              id="is-auto-true"
                              type="checkbox"
                              v-model="searchParam.isAutoUpdate"
                              :value="true"
                          />
                          <label class="c-checkbox__label" for="is-auto-true">
                            <span class="c-checkbox__box"></span>
                            自動
                          </label>
                        </div>
                      </div>
                      <div class="c-input">
                        <div class="c-checkbox">
                          <input
                              id="is-auto-false"
                              type="checkbox"
                              v-model="searchParam.isAutoUpdate"
                              :value="false"
                          />
                          <label class="c-checkbox__label" for="is-auto-false">
                            <span class="c-checkbox__box"></span>
                            手続き
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="l-container">
          <div class="l-btns l-block-lg">
            <div class="c-btn secondary small" @click="resetSearchHandler">
              条件をリセット
            </div>
            <div class="c-btn primary small" @click="searchHandler">検索</div>
          </div>
        </div>
      </div>

      <!-- 検索結果 ============================================================ -->
      <div class="l-container">
        <div class="c-infoList scroll l-box">
          <div class="c-infoList__row head">
            <div class="c-infoList__cell md">
              <a @click="clickSortChangeHandler">
                <span
                    class="c-infoList__cell__sort"
                    :class="{
                    asc: sortParams.type.toLowerCase() === 'asc',
                    desc: sortParams.type.toLowerCase() === 'desc',
                  }"
                >企業名</span>
              </a>
            </div>
            <div class="c-infoList__cell sm">電話番号</div>
            <div class="c-infoList__cell sm">契約情報</div>
            <div class="c-infoList__cell sm">アカウント開放日</div>
            <div class="c-infoList__cell sm">契約開始日</div>
            <div class="c-infoList__cell sm">契約終了日</div>
          </div>

          <!-- 検索結果あり -->
          <template v-if="contractList.length">
            <div
                v-for="contract of contractList"
                :key="'contract-' + contract.id"
                :to="`/generator/contract/${contract.id}`"
                class="c-infoList__row list"
            >
              <!-- 企業名 -->
              <template v-if="contract.contractEndLimit">
                <div class="c-infoList__cell md c-tooltip">
                  <div class="c-infoText">
                    <div>
                      <router-link :to="`/generator/contract/${contract.id}`">
                        {{ contract.name }}
                      </router-link>
                    </div>
                  </div>
                  <div class="c-status" :class="contract.contractEndLimit">
                    <div class="c-infoList__popup sm">
                      <span v-for="(msg, msgIndex) of contract.msgs" :key="'msg-' + msgIndex">{{ msg }}<br></span>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="c-infoList__cell md">
                  <router-link :to="`/generator/contract/${contract.id}`">
                    {{ contract.name }}
                  </router-link>
                </div>
              </template>

              <div class="c-infoList__cell sm">{{ contract.tel | blankText }}</div>
              <div class="c-infoList__cell sm">
                {{ contract.generateContractSearchStatus ? contract.generateContractSearchStatus.name : '' }}
              </div>
              <div class="c-infoList__cell sm">
                {{ contract.accountStartDate | blankText }}
              </div>
              <div class="c-infoList__cell sm">
                {{ contract.cbaContractStartDate | blankText }}
              </div>
              <div class="c-infoList__cell sm">
                {{ contract.cbaContractEndDate | blankText }}
              </div>
            </div>
          </template>

          <!-- 検索結果なし -->
          <template v-else>
            <div class="c-infoList__row list">
              <div class="c-infoList__cell">
                検索条件に一致する排出事業者契約がありません。
              </div>
            </div>
          </template>
        </div>

        <Pagination v-if="contractList.length"
            :params="paginationParams"
            :page.sync="paginationParams.page"
            @callback="getContractSearchApi(true)"
        />
      </div>
    </main>
    <AppFooter />
  </div>
</template>

<script>
import moment from "moment";
import InputText from "@/components/parts/input/InputText";
import InputDate from "@/components/parts/input/InputDate";
import Pagination from "@/components/parts/Pagination";
import {DOMAIN} from "@/const";
import validation from "@/mixin/validation";
import search from "./mixins/search";
import {DATE_LIMIT_STATUS, CONTRACT_END_ALERT_INTERVAL} from "@/const/dateLimit";

export default {
  name: "generator-contract-list",
  components: {
    InputText,
    InputDate,
    Pagination,
  },
  mixins: [validation, search],
  data() {
    return {
      contractRegistCount: {
        notEntrustCount: null,
        notAccountStartDateCount: null,
        reservedAccountStartDateCount: null,
        completeCount: null,
      },
      contractList: [],
      isDisplaySearch: false,
      isSearchDetail: this.$store.getters["searchParamGeneratorContract/isSearchDetail"],
      statusOpt: [
        { id: DOMAIN.GENERATE_CONTRACT_SEARCH_STATUS.NOT_ENTRUST, name: "委託未契約" },
        { id: DOMAIN.GENERATE_CONTRACT_SEARCH_STATUS.MAIL_NOTSETTING, name: "開放日未設定" },
        { id: DOMAIN.GENERATE_CONTRACT_SEARCH_STATUS.MAIL_RESERVED, name: "準備完了予約済" },
        { id: DOMAIN.GENERATE_CONTRACT_SEARCH_STATUS.COMPLETE, name: "有効"},
      ],
      // ページネーション用
      paginationParams: {
        page: 1,
        pageSize: 20,
        total: null,
      },
      sortParams: {
        column: "contract_name",
        type: "asc",
      },
      // 検索v-model
      searchParam: {
        keySearch: "",
        status: [],
        accountStartDateFrom: "",
        accountStartDateTo: "",
        cbaContractEndDateFrom: "",
        cbaContractEndDateTo: "",
        isAutoUpdate: [],
      },
    };
  },
  methods: {
    // 期限ステータスチェック
    checkLimitDiff(date) {
      const today = moment();
      const targetDate = moment(date);
      return targetDate.diff(today, "days");
    },
    checkLimitStatus(date) {
      const diff = this.checkLimitDiff(date);
      if (diff < 0) {
        return DATE_LIMIT_STATUS.DANGER;
      } else if (diff < CONTRACT_END_ALERT_INTERVAL) {
        return DATE_LIMIT_STATUS.WARNING;
      } else {
        return DATE_LIMIT_STATUS.NOT_YET;
      }
    },

    showcount(id) {
      switch (id) {
        case DOMAIN.GENERATE_CONTRACT_SEARCH_STATUS.NOT_ENTRUST:
          return this.contractRegistCount.notEntrustCount;
        case DOMAIN.GENERATE_CONTRACT_SEARCH_STATUS.MAIL_NOTSETTING:
          return this.contractRegistCount.notAccountStartDateCount;
        case DOMAIN.GENERATE_CONTRACT_SEARCH_STATUS.MAIL_RESERVED:
          return this.contractRegistCount.reservedAccountStartDateCount;
        case DOMAIN.GENERATE_CONTRACT_SEARCH_STATUS.COMPLETE:
          return this.contractRegistCount.completeCount;
        default:
          return null;
      }
    },

    initSearch(isAll = true) {
      this.searchParam = {
        keySearch: isAll ? "" : this.searchParam.keySearch,
        status: isAll ? [] : this.searchParam.status,
        accountStartDateFrom: "",
        accountStartDateTo: "",
        cbaContractEndDateFrom: "",
        cbaContractEndDateTo: "",
        isAutoUpdate: [],
      };
    },

    resetSearchHandler() {
      this.initSearch();
    },

    searchHandler() {
      this.getContractSearchApi();
    },

    // 企業名ソート変更
    clickSortChangeHandler() {
      this.sortParams.type = this.sortParams.type.toLowerCase() === "asc" ? "desc" : "asc";
      this.getContractSearchApi(false);
    },

    handleScroll() {
      this.scrollY = window.scrollY;
      if (!this.isDisplaySearch) {
        this.isDisplaySearch = window.scrollY > 200;
      } else if (window.scrollY < 190) {
        this.isDisplaySearch = !this.isDisplaySearch;
      }
    },

    scrollTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    shorten() {
      const result = [];
      const prev = this.$store.getters["searchParamGeneratorContract/get"];

      if (prev.keySearch) result.push({ key: "キーワード", value: prev.keySearch });
      if (prev.status.length) result.push({ key: "契約状況", value: prev.status.map(b => this.statusOpt.filter(opt => opt.id === b)[0].name).join("、") });
      if (prev.accountStartDateFrom || prev.accountStartDateTo) result.push({ key: "アカウント開放日", value: prev.accountStartDateFrom + "〜" + prev.accountStartDateTo });
      if (prev.cbaContractEndDateFrom || prev.cbaContractEndDateTo) result.push({ key: "契約終了日", value: prev.cbaContractEndDateFrom + "〜" + prev.cbaContractEndDateTo });
      if (prev.isAutoUpdate.length === 1) result.push({ key: "契約形態", value: this.isAutoUpdate[0] ? "自動" : "手続き" });

      return result;
    },
  },

  watch: {
    isSearchDetail() {
      this.initSearch(false);
    },

    contractList() {
      this.contractList.forEach(contract => {
        contract.msgs = [];
        contract.contractEndLimit = this.checkLimitStatus(contract.cbaContractEndDate);
        if (contract.contractEndLimit === DATE_LIMIT_STATUS.WARNING) {
          let warning = `契約期限まであと ${this.checkLimitDiff(contract.cbaContractEndDate)+1} 日`;
          contract.msgs.push(warning);
        } else if (contract.contractEndLimit === DATE_LIMIT_STATUS.DANGER) {
          const danger = "契約期限が過ぎています。";
          contract.msgs.push(danger);
        }
      });
    }
  },

  beforeCreate() {
    // クエリパラメータのチェック
    if (this.$route.query.status) {
      this.$store.commit("searchParamGeneratorContract/set", {
        keySearch: "",
        status: this.$route.query.status.split(",").map(s => Number(s)),
        accountStartDateFrom: "",
        accountStartDateTo: "",
        cbaContractEndDateFrom: "",
        cbaContractEndDateTo: "",
        isAutoUpdate: [],
      });
    }
  },

  mounted() {
    this.searchParam = this.$store.getters["searchParamGeneratorContract/get"];

    window.addEventListener("scroll", this.handleScroll);

    this.getResistCountApi();
    this.getContractSearchApi();
  },
};
</script>
